import React from 'react';

import { styled, theme } from '../../Theme';
import { pseudo } from '@glitz/core';

import {
  EventDispatcher,
  ON_MOBILE_MENU_OPEN_CHANGE,
} from '../../Shared/Common/EventDispatcher';

type PropTypes = {
  isOpen: boolean;
};

function MenuButton({ isOpen }: PropTypes) {
  return (
    <Button
      onClick={() =>
        EventDispatcher.dispatch(ON_MOBILE_MENU_OPEN_CHANGE, !isOpen)
      }
    >
      <Hamburger data-isopen={isOpen}>
        <BarTop data-isopen={isOpen} />
        <BarMid data-isopen={isOpen} />
        <BarBot data-isopen={isOpen} />
      </Hamburger>
    </Button>
  );
}

const Button = styled.button({
  width: theme.menuButtonWidth,
  height: theme.menuButtonHeight,
});

const Hamburger = styled.div({
  position: 'relative',
  height: '100%',
  width: '100%',
  transition: {
    property: 'transform',
    timingFunction: '$main-timing-function',
    duration: '0.3s',
  },
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    transform: 'rotate(45deg)',
    transformOrigin: 'center',
  }),
});

const BarTop = styled.span({
  backgroundColor: theme.white,
  display: 'block',
  position: 'absolute',
  borderRadius: theme.smaller,
  height: theme.smaller,
  width: '100%',
  left: '50%',
  top: 0,
  transform: 'translateX(-50%)',
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    backgroundColor: theme.primaryBlue,
    position: 'absolute',
    transform: 'rotate(90deg) translate(-50%, -50%)',
    transformOrigin: 'left top',
    top: '50%',
  }),
});

const BarMid = styled.span({
  backgroundColor: theme.white,
  display: 'block',
  position: 'absolute',
  borderRadius: theme.smaller,
  height: theme.smaller,
  width: '100%',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    backgroundColor: theme.primaryBlue,
  }),
});

const BarBot = styled.span({
  backgroundColor: theme.white,
  display: 'block',
  position: 'absolute',
  borderRadius: theme.smaller,
  height: theme.smaller,
  width: '100%',
  left: '50%',
  bottom: 0,
  transform: 'translateX(-50%)',
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    opacity: 0,
  }),
});

export default MenuButton;
