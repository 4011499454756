import React, { useState, useCallback, useRef } from 'react';
import { theme, styled } from '../../Theme';
import useOutsideClick from '../Hooks/useOutsideClick';
import { pseudo } from '@glitz/core';
import SearchModal from '../../SearchModal/SearchModal';

import SearchInput from '../../SearchPage/SearchInput';
import FullSearchResult from '../../Search/Models/FullSearchResult.interface';

type PropTypes = {
  isOpen: boolean;
  setSearchOpen: any;
};

function MobileQuickSearch({ isOpen, setSearchOpen }: PropTypes) {
  const [searchResult, setSearchResult] = useState<FullSearchResult>();
  const [isSearchInputActive, setIsSearchInputActive] = useState(false);
  const [currentSearchPhrase, setCurrentSearchPhrase] = useState('');

  const searchRef = useRef<HTMLDivElement>(null);

  useOutsideClick(searchRef, () => {
    isOpen && setSearchOpen(false);
  });

  const onSearchResultChange = useCallback((res, searchPhrase) => {
    setCurrentSearchPhrase(searchPhrase);
    setSearchResult(res);
  }, []);

  return (
    <>
      <Container ref={searchRef} data-isopen={isOpen}>
        {isOpen && (
          <SearchInput
            onSearchResultChange={onSearchResultChange}
            isActive={isSearchInputActive}
            setIsActive={setIsSearchInputActive}
            inputValue={currentSearchPhrase}
            setInputValue={setCurrentSearchPhrase}
          />
        )}
      </Container>
      {isOpen && (
        <SearchModal
          searchResult={searchResult}
          isOpen={isSearchInputActive}
          currentSearchPhrase={currentSearchPhrase}
          setInputActive={setIsSearchInputActive}
          onSearchResultChange={onSearchResultChange}
        />
      )}
    </>
  );
}

const Container = styled.div({
  height: 0,
  width: '100%',
  backgroundColor: theme.lighterDarkGray,
  display: 'flex',
  alignItems: 'center',
  padding: { x: theme.spacing(4) },
  overflow: 'hidden',
  transition: {
    duration: theme.timings.oneThird,
    property: 'all',
    timingFunction: 'ease-out',
  },
  ...pseudo([':nth-child(n)[data-isopen="true"]'], {
    height: '77px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(5),
  }),
});

export default MobileQuickSearch;
