import React from 'react';

import { styled, theme } from '../../Theme';
import { pseudo } from '@glitz/core';
import SearchIconWhite from '../Icons/SearchIconWhite';

import {
  EventDispatcher,
  ON_MOBILE_SEARCH_OPEN_CHANGE,
} from '../../Shared/Common/EventDispatcher';

type PropTypes = {
  searchIsOpen: boolean;
};

function SearchButton({ searchIsOpen }: PropTypes) {
  return (
    <StyledSearchButton
      onClick={() =>
        EventDispatcher.dispatch(ON_MOBILE_SEARCH_OPEN_CHANGE, !searchIsOpen)
      }
    >
      {searchIsOpen ? <StyledSearchIconBlue /> : <StyledSearchIconWhite />}
    </StyledSearchButton>
  );
}

const StyledSearchButton = styled.button({
  paddingRight: theme.spacing(8),
  paddingLeft: theme.spacing(5),
});

const StyledSearchIconWhite = styled(SearchIconWhite, {
  height: '26px',
  width: '26px',
  fill: theme.white,
  ...pseudo([':nth-child(n)[data-menuisopen="true"]'], {
    display: 'none',
  }),
});

const StyledSearchIconBlue = styled(SearchIconWhite, {
  height: '26px',
  width: '26px',
  fill: theme.iconLightBlue,
  ...pseudo([':nth-child(n)[data-menuisopen="true"]'], {
    display: 'none',
  }),
});

export default SearchButton;
