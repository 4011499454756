import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function SearchIconWhite({ compose }: StyledProps) {
  return (
    <styled.Svg css={compose()} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.95 17.9C4.028 17.9 0 13.89 0 8.95 0 4.028 4.028 0 8.95 0a8.952 8.952 0 0 1 8.95 8.95 8.935 8.935 0 0 1-2.084 5.715l6.946 6.946a.814.814 0 0 1-1.151 1.15l-6.946-6.945A8.788 8.788 0 0 1 8.95 17.9zm0-16.5C4.796 1.4 1.4 4.796 1.4 8.95s3.376 7.55 7.55 7.55a7.545 7.545 0 0 0 7.55-7.55c0-4.154-3.376-7.55-7.55-7.55z"
        fillRule="nonzero"
      />
    </styled.Svg>
  );
}

export default styled(SearchIconWhite);
