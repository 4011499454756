import React, { useEffect, useState } from 'react';

import { styled, theme } from '../../../../Theme';
import { pseudo } from '@glitz/core';
import MobileQuickSearch from '../../../MobileQuickSearch/MobileQuickSearch';
import KexLink from '../../../KexLink/KexLink';
import { useAppSettingsData } from '../../../AppSettingsProvider/AppSettingsProvider';
import HeaderIconLink from '../HeaderIconLink/HeaderIconLink';
import { LinkType } from '../../../../Enums/LinkType.enum';
import MenuButton from '../../../../Shared/MenuButton/MenuButton';
import SearchButton from '../../../../Shared/SearchButton/SearchButton';

import {
  EventDispatcher,
  ON_MOBILE_MENU_OPEN_CHANGE,
  ON_MOBILE_SEARCH_OPEN_CHANGE,
  ON_MINICART_OPEN_CHANGE,
} from '../../../../Shared/Common/EventDispatcher';
import { useUserStateData } from '../../../UserContextProvider/UserContextProvider';

function MobileHeader() {
  const {
    languageRoute,
    translations: {
      'common/contact': contactLabel,
      'account/signIn': signInLabel,
      'account/signOut': signOutLabel,
      'common/myPages': myPagesLabel,
      'common/cart': cartLabel,
    },
    staticPages: { loginPage, contactUs, myPage },
  } = useAppSettingsData();

  const { authenticated } = useUserStateData();

  const logoUrl = '/Dist/img/pictura-logo.png';

  const onMenuOpenChange = (setIsActive: any, isOpen: boolean) => {
    setIsActive(isOpen);
  };

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [miniCartIsOpen, setMiniCartIsOpen] = useState(false);

  useEffect(() => {
    const onSearchOpenChange = (isOpen: boolean) => {
      setSearchOpen(isOpen);
    };

    EventDispatcher.subscribe(ON_MOBILE_SEARCH_OPEN_CHANGE, onSearchOpenChange);

    const onMiniCartOpenChange = (miniCartIsOpen: boolean) => {
      setMiniCartIsOpen(miniCartIsOpen);
    };

    EventDispatcher.subscribe(ON_MINICART_OPEN_CHANGE, onMiniCartOpenChange);

    return () => {
      EventDispatcher.unsubscribe(
        ON_MOBILE_SEARCH_OPEN_CHANGE,
        onSearchOpenChange
      );
      EventDispatcher.unsubscribe(
        ON_MINICART_OPEN_CHANGE,
        onMiniCartOpenChange
      );
    };
  }, []);

  useEffect(() => {
    const boundOnMenuOpenChange = onMenuOpenChange.bind(null, setIsMenuActive);
    EventDispatcher.subscribe(
      ON_MOBILE_MENU_OPEN_CHANGE,
      boundOnMenuOpenChange
    );

    return () =>
      EventDispatcher.unsubscribe(
        ON_MOBILE_MENU_OPEN_CHANGE,
        boundOnMenuOpenChange
      );
  }, [isMenuActive]);

  const iconLinkOnClink = () => {
    //useOutsideClick sometimes glitches when clicking icon links, therefore this is needed
    isMenuActive &&
      EventDispatcher.dispatch(ON_MOBILE_MENU_OPEN_CHANGE, !isMenuActive);
    searchOpen &&
      EventDispatcher.dispatch(ON_MOBILE_SEARCH_OPEN_CHANGE, !searchOpen);
  };

  return (
    <>
      <Placeholder />
      <Header id="header">
        <MobileHeaderContainer>
          <MobileHeaderTopSection onClick={iconLinkOnClink}>
            {authenticated && (
              <HeaderIconLink
                linkType={LinkType.MyPages}
                label={myPagesLabel}
                href={myPage}
              />
            )}
            <HeaderIconLink
              linkType={LinkType.Contact}
              label={contactLabel}
              href={contactUs}
            />
            {authenticated && (
              <HeaderIconLink
                linkType={LinkType.MiniCart}
                label={cartLabel}
                miniCartIsOpen={miniCartIsOpen}
              />
            )}
            <HeaderIconLink
              linkType={!authenticated ? LinkType.SignIn : LinkType.SignOut}
              label={!authenticated ? signInLabel : signOutLabel}
              href={!authenticated ? loginPage : ''}
            />
          </MobileHeaderTopSection>
          <MobileHeaderBottomSection data-menuisopen={isMenuActive}>
            <ImageWrapper data-menuisopen={isMenuActive}>
              <KexLink href={`/${languageRoute}/`}>
                <Logo src={logoUrl} alt={'logo'} />
              </KexLink>
            </ImageWrapper>
            <BottomSectionButtonsWrapper>
              {!isMenuActive && <SearchButton searchIsOpen={searchOpen} />}
              <MenuButton isOpen={isMenuActive} />
            </BottomSectionButtonsWrapper>
          </MobileHeaderBottomSection>
          <MobileQuickSearch
            isOpen={searchOpen}
            setSearchOpen={setSearchOpen}
          />
        </MobileHeaderContainer>
      </Header>
    </>
  );
}

export default MobileHeader;

const Placeholder = styled.div({
  width: '100%',
  padding: { top: theme.mobileHeaderHeight },
});

const Header = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.Header,
  backgroundColor: theme.darkGray,
});

const MobileHeaderContainer = styled.header({
  width: '100%',
  zIndex: theme.zIndex.HeaderMobile,
});

const MobileHeaderTopSection = styled.div({
  display: 'flex',
  backgroundColor: theme.darkGray,
  zIndex: theme.zIndex.HeaderMobile,
  height: theme.mobileHeaderTopSectionHeight,
  padding: { x: '15px', y: theme.medium },
});

const MobileHeaderBottomSection = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  height: theme.mobileHeaderBottomSectionHeight,
  backgroundColor: theme.lighterDarkGray,
  padding: { x: '15px', y: '12px' },
  alignItems: 'center',
  transition: {
    property: 'all',
    duration: theme.timings.threeTenths,
    timingFunction: 'ease',
  },
  ...pseudo([':nth-child(n)[data-menuisopen="true"]'], {
    backgroundColor: theme.white,
    justifyContent: 'flex-end',
  }),
});

const ImageWrapper = styled.div({
  transition: {
    property: 'all',
    duration: theme.timings.threeTenths,
    timingFunction: 'ease',
  },
  ...pseudo([':nth-child(n)[data-menuisopen="true"]'], {
    display: 'none',
  }),
});

const BottomSectionButtonsWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Logo = styled.img({
  height: '48px',
});
